<template>
  <div class="block-wrapper">
    <a-form @submit.stop.prevent="handleSubmit">
      <a-form-item label="Статус">
        <a-checkbox v-model="make.is_active">
          Активный
        </a-checkbox>
      </a-form-item>

      <a-form-item label="Название">
        <a-input v-model="make.name" required />
      </a-form-item>

      <a-form-item label="Значение">
        <a-input v-model="make.slug" required />
      </a-form-item>

      <a-form-item :style="{ display: 'flex', justifyContent: 'flex-end' }">
        <a-button @click.prevent="$router.go(-1)">
          Назад
        </a-button>
        <a-button
          :style="{ marginLeft: '15px' }"
          type="primary"
          html-type="submit"
        >
          Сохранить
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { showNotification } from '@/utils'
import { GET_MAKE, UPDATE_MAKE } from '@/store/types'

export default {
  data() {
    return {
      make: {
        name: '',
        slug: '',
        is_active: false,
      },
    }
  },

  created() {
    this.getMake()
  },

  methods: {
    handleSubmit() {
      this.$store.dispatch(UPDATE_MAKE, this.make)
    },

    async getMake() {
      try {
        this.make = await this.$store.dispatch(GET_MAKE, this.$route.params.id)
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
  },
}
</script>
